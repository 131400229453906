import React, { lazy, memo, Suspense } from 'react';
import { TimeRange } from '@grafana/data';
import { DateTimeRange } from 'store/timeRange';
import { DataSourceRef } from '@grafana/schema';

const LazyObjectDetailExtension = lazy(() => import('./ObjectDetailExtension'));

export interface ObjectDetailExtensionProps {
  entityType: 'Node' | 'Namespace' | 'Workload' | 'KubeCluster' | 'Pod';
  entityName: string;
  clusterName: string;
  initialTimeRange: DateTimeRange;
  namespace?: string;
  workloadType?: string;
  workload?: string;
  locationTitle?: string;
  onTimeRangeChange: (timeRange: TimeRange) => void;
  promDatasourceRef: DataSourceRef;
  lokiDatasourceRef: DataSourceRef;
}

const ObjectDetailExtension = (props: ObjectDetailExtensionProps) => (
  <Suspense fallback={null}>
    <LazyObjectDetailExtension {...props} />
  </Suspense>
);

export const ObjectDetailExtensionParams = {
  title: 'K8s Object Detail',
  description: 'Metrics details for various k8s objects',
  id: 'grafana-k8s-app/object-detail-extension/v1',
  component: memo(ObjectDetailExtension),
};
